import React, { useState, useEffect } from 'react'

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

import './styles.css'


const SubscriptionBox = ({ children, index, checked, onClicked, price, originalPrice }) => {
  const [isChecked, setChecked] = useState(checked === index);

  const handleChange = (e) => {
      onClicked(index);
  }

  useEffect(() => {
      setChecked(checked === index);
  }, [checked])

  return (
    <Paper elevation={isChecked? 5 : 1} onClick={handleChange} sx={{
        position:"relative",
        cursor:"pointer",
      }}
    >
        <Stack direction="row" alignItems="center" px={1} py={2}>
            <Checkbox checked={isChecked} onChange={handleChange}/>
            <Typography sx={{ flexGrow: 1 }} variant="body1" fontWeight="bold">{children}</Typography>
            <Typography pr={2} fontWeight="bold" sx={{position:"relative"}}>
                {originalPrice &&
                  <Typography
                    className="discounted"
                    sx={{
                      fontSize:"0.865rem",
                      position:"absolute",
                      top:-20,
                      left:8,
                    }}
                  >
                    ${originalPrice}
                  </Typography>
                }
                {price}
                <Typography variant="caption" fontWeight="light">/Term</Typography>
            </Typography>
        </Stack>
        {isChecked && <Box sx={{
                position: "absolute",
                top:0,
                right:0,
                width:0,
                height:0,
                borderTop:"0px solid transparent",
                borderBottom: "40px solid transparent",
                borderRight: "40px solid #007bfc"
            }}></Box>
        }
    </Paper>
  )
}

export default SubscriptionBox
