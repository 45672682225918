import { useState } from 'react';
import { MantineProvider, ColorSchemeProvider } from '@mantine/core';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import ColorToggle from './components/colorToggle';
import Homepage from './Homepage/Homepage';
import Hosting from './Hosting/Hosting';
import User from './User/User';
import AppShell from './AppShell';
import PasswordReset from './PasswordReset/PasswordReset';

import './App.css';

function App() {
  const [darkState, setDarkState] = useState(true);
  const darkTheme = createTheme({
        palette: {
          mode: darkState ? 'dark' : 'light',
        }
  });

  const handleThemeChange = () => {
      setDarkState(!darkState);
  };
  return (
   <ThemeProvider theme={darkTheme}>
   <CssBaseline />
   <Router>
        <AppShell darkMode={darkState} DarkModeToggle={handleThemeChange}>
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/endpoint" element={<Homepage />} />
                    <Route path="/hosting" element={<Hosting />} />
                    <Route path="/account" element={<User />} />
                    <Route path="/reset/:uid/:token/" element={<PasswordReset />} />
                </Routes>
        </AppShell>
    </Router>
    </ThemeProvider>

  );
}

export default App;
