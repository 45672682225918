import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';


// import {
//   AppShell,
//   Navbar,
//   Header,
//   Footer,
//   Aside,
//   Text,
//   MediaQuery,
//   Burger,
//   Container,
//   useMantineTheme,
// } from '@mantine/core';

import AppHeader from './components/Header';

export default function AppShell(props) {
  return (
      <Box>
        <Container maxWidth="lg">
            <AppHeader darkMode={props.darkMode} DarkModeToggle={props.DarkModeToggle}/>
            <Box sx={{
                minHeight:"100vh",
                paddingTop:"40px",
                paddingBottom:"40px"
            }}>
                {props.children}
            </Box>
        </Container>
      </Box>
  );
}
