import React, { useState, useEffect } from 'react'

import { api } from "../../api";

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ErrorIcon from '@mui/icons-material/Error';

import DeleteLinode from './DeleteLinode';

import server from "../../assets/server.png"
import powerOff from "../../assets/power-off.png"
import powerOn from "../../assets/power.png"

import RenderStatus from "./RenderStatus";

function CopyButton(props) {
    return (
        <InputAdornment position="end">
          <IconButton edge="end" color="primary" onClick={() => navigator.clipboard.writeText(props.text)}>
            <ContentCopyIcon />
          </IconButton>
        </InputAdornment>
    )
}


const Endpoint = ({ep, refresh}) => {
  const [ingest, setIngest] = useState("");
  const [type, setType] = useState(ep?.endpoint_type)
  const [stats, setStats] = useState("");
  const [event, setEvent] = useState({})
  const [isCloned, setIsCloned] = useState(false)
  const handlePower = (e) => {
      api.switch_power({id:ep.linode_id})
  }

  const cloneSuccessful = (b) => {
      setIsCloned(b)
  }

  useEffect(() => {
      if (ep.endpoint_type === "rtmp") {
          setIngest(`rtmp://${ep.ipv4_external}:1935/publish/live`)
      } else if (ep.endpoint_type === "srt") {
          setIngest(`srt://${ep.ipv4_external}:5000?streamid=publish/live/feed1`)
      } else if (ep.endpoint_type === "srtla") {
          setIngest(`srt://${ep.ipv4_external}:8282?streamid=publish/live/feed1`)
      }

      if (ep.endpoint_type === "rtmp") {
          setStats(`http://${ep.ipv4_external}/stats`)
      } else {
          setStats(`http://${ep.ipv4_external}:8181/stats`)
      }
  }, [])

  useEffect(() => {
      if (type === "rtmp") {
          setIngest(`rtmp://${ep.ipv4_external}:1935/publish/live`)
      } else if (type === "srt") {
          setIngest(`srt://${ep.ipv4_external}:8282?streamid=publish/live/feed1`)
      } else if (type === "srtla") {
          setIngest(`srt://${ep.ipv4_external}:5000?streamid=publish/live/feed1`)
      }
  }, [type])

  useEffect(() => {
      api.get_status(ep.linode_id).then((result) => {
          setEvent(result?.data)
      })
  }, [])

  return (
      <Grid item xs={12} md={6} mt={2}>
          <Paper
            sx={{
                position:"relative",
                display:"flex",
                alignItems:"center",
                width: {xs: "100%", md:"100%"},
                height:"100%",
                p:{xs: 1, sm:3, md: 2},
                borderRadius:3,
            }}>
            { ep.remaining || ep.remaining === 0  ?
                <Box sx={{
                    position:"absolute",
                    right:15,
                    top:15,
                }}>
                    <Typography variant="caption" color="red">{`Expires in ${ep.remaining} days`}</Typography>
                </Box>

              :
                <DeleteLinode linode_id={ep.linode_id} onConfirm={refresh}/>
            }
            <Grid container alignItems="center" spacing={2} justifyContent={{xs:"center"}} mt={{xs:3, md:0}}>
                <Grid item md={12} sx={{
                        display:{
                            xs: "none",
                            sm: "block",
                        }
                    }}>
                    <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", marginRight: 1}} onClick={handlePower}>
                        <img style={{width:"64px", height:"64px"}} src={server} alt=""/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Stack sx={{flexGrow:2, justifyContent:"center", textAlign:"center"}}>
                      <Typography fontWeight="light">{ep.label}</Typography>
                      <Typography>{ep.region}</Typography>
                    </Stack>
                </Grid>
                { isCloned && <>
                <Grid item xs={12} md={12}>
                    <Box sx={{
                            position:"relative",
                            display:"flex",
                            flexGrow: 1,
                            alignItems:"center",
                         }}>
                        { ep.status !== "running" &&
                            <Box sx={{
                                position:"absolute",
                                display:"flex",
                                bottom:"-50%",
                                width:"105%",
                                p:2,
                                borderRadius:2,
                                backgroundColor:"rgb(250, 250, 250)",
                                left:"50%",
                                transform: "translate(-50%, 50%)",
                            }}>
                                <Stack sx={{ width:"100%" }} justifyContent="center" alignItems="center">
                                    <ErrorIcon sx={{ fontSize:128 }} color="error"/>
                                    <Typography color="error">Boot your endpoint first!</Typography>
                                </Stack>
                            </Box>
                        }
                      <TextField fullWidth
                          label={`${type.toUpperCase()} Ingest URL`}
                          disabled
                          value={ingest}
                          InputProps={{
                                      endAdornment: (
                                        <CopyButton text={ingest}/>
                                      ),
                                    }}
                          />
                    </Box>
                    {(type === "srt" || type === "srtla") &&
                      <div>
                          <Button onClick={(e) => setType("srt")}>SRT</Button>
                          <Button onClick={(e) => setType("srtla")}>SRTLA</Button>
                      </div>
                    }
                </Grid>
                <Grid item xs={12} md={12}>
                    <Box sx={{
                            display:"flex",
                            flexGrow: 1,
                            alignItems:"center",
                         }}>
                      <TextField fullWidth
                          label="Stats URL"
                          disabled
                          value={stats}
                          InputProps={{
                                      endAdornment: (
                                        <CopyButton text={stats}/>
                                      ),
                                    }}
                          />
                    </Box>
                </Grid>
                </>
                }
                <Grid item xs={12} md={12} sx={{textAlign:"center"}}>
                    <Link href="#" target="_blank">{"How to set this up"}</Link>
                    <Box sx={{
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center",
                            gap:3,
                            marginTop: 2,
                        }}>
                      <Stack>
                        <RenderStatus linodeId={ep.linode_id} event={event} linodeStatus={ep.status} cloneSuccessful={cloneSuccessful}/>
                      </Stack>
                    </Box>
                </Grid>
            </Grid>
          </Paper>
      </Grid>
  )
}

export default Endpoint
