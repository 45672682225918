import React, { useState } from 'react'
import { useParams, useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Snackbar from "@mui/material/Snackbar";
import Alert from '@mui/material/Alert';

import { api } from '../api';

const PasswordReset = () => {
    const [new_password, resetPassword] = useState("");
    const [openFailed, setOpenFailed] = useState(false);
    const [failContent, setFailContent] = useState("");

    const {uid, token} = useParams();
    const navigate = useNavigate();

    const closeFailed = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpenFailed(false);
    };

    const openFailAlert = (content) => {
        setFailContent(content);
        setOpenFailed(true);
    }

    const handleConfirm = () => {

        const data = {
            uid,
            token,
            new_password,
        }
        api.reset_password_confirm(data).then((result) => {
            navigate("/account");
        }).catch((err) => {
            if (err.response.data.new_password) {
                openFailAlert(err.response.data.new_password);
            } else {
                openFailAlert("An error happend! Please contact admin");
            }
        })
    }

  return (
      <Box sx={{
              marginTop: 30,
          }}
      >
          <Snackbar
            anchorOrigin={{ vertical:"top", horizontal:"center" }}
            open={openFailed}
            autoHideDuration={6000}
            onClose={closeFailed}
          >
            <Alert
                onClose={closeFailed}
                severity="error"
                elevation={6} variant="filled">
              {failContent}
            </Alert>
          </Snackbar>
          <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} md={9}>
                <Typography textAlign="center" variant="h6">Password Reset</Typography>
                <Box mx="auto" mt={2} width="60%">
                    <TextField onChange={(e) => resetPassword(e.target.value)} fullWidth label="New password" />
                </Box>
                <Box textAlign="center" mt={2}>
                    <Button onClick={handleConfirm} variant="contained" color="success">Confirm</Button>
                </Box>
              </Grid>
          </Grid>
      </Box>
  )
}

export default PasswordReset
