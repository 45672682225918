import React, { useState, useEffect } from 'react'

import { api } from "../../api";

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import StripeLogo from "../../assets/stripe.svg"


const Transactions = () => {
    const [transactions, setTransactions] = useState()
    useEffect(() => {
        api.get_transactions().then((result) => {
            setTransactions(result?.data)
        })
    }, [])
  return (
    <>
        {transactions ?
            transactions.map((tr, index) => (
            <Grid item xs={12} key={index}>
                <Paper
                  sx={{
                      display:"flex",
                      alignItems:"center",
                      width:{xs:"100%", md:"100%"},
                      p:2,
                      borderRadius:3,
                  }}>
                  <Grid
                      justifyContent={{xs:"center"}}
                      container
                      direction="row" spacing={1} sx={{width:"100%"}}>
                      <Grid item xs={12} md={2}>
                          <Box sx={{display:"flex", justifyContent:"center"}}>
                              <img style={{width:"86px"}} src={StripeLogo} alt=""/>
                          </Box>
                      </Grid>
                      <Grid item xs={12} md={7}>
                          <Stack sx={{textAlign:"center"}}>
                            <Typography fontWeight="light">{`Order #${tr.order}`}</Typography>
                            <Typography variant="caption" fontWeight="light">{new Date(tr.created).toLocaleString()}</Typography>
                          </Stack>
                      </Grid>
                      <Grid item xs={12} md={3}>
                          <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", gap:3}}>
                            <Typography>${tr.amount}</Typography>
                            <Box>
                              <Alert severity={tr.status === "paid" ? "success" : "warning"}>{tr.status === "paid" ? "Completed" : "Pending"}</Alert>
                            </Box>
                          </Box>
                      </Grid>
                </Grid>
                </Paper>
            </Grid>
        )) :
            <Box sx={{ display: 'flex',
                 width:"100%",
                 justifyContent: "center", height:"10vh", alignItems:"center" }}>
                <CircularProgress />
            </Box>
        }
    </>
  )
}

export default Transactions
