import React, {useState, useEffect} from 'react'

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from "@mui/material/Snackbar";
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';

import CMCircularProgress from '../../components/CircularProgressLabeled';

import powerOff from "../../assets/power-off.png"
import powerOn from "../../assets/power.png"

import { api } from "../../api"

function getHourOrMinute(time) {
    if (!time) {
        return ""
    }
    const timeArr = time.split(":")
    if (timeArr.length === 3) {
        if (timeArr[0] === 0) {
            return `>${timeArr[0]} Hours remaining`
        }
        else {
            return `~${timeArr[1]} Minutes remaining`
        }
    } else if (timeArr.length === 2) {
        return `~${timeArr[1]} Minutes remaining`
    }
}


const RenderStatus = ({linodeId, event, linodeStatus, cloneSuccessful}) => {
  const [percent, setPercent] = useState(event.percent)
  const [time, setTime] = useState(event.remaining)
  const [status, setStatus] = useState(linodeStatus)
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailed, setOpenFailed] = useState(false);
  const [failContent, setFailContent] = useState("");

  const closeSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const closeFailed = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailed(false);
  };

  useEffect(() => {
      api.get_status(linodeId).then((result) => {
          if (result?.data?.percent !== 100) {
              setPercent(result?.data?.percent)
              setTime(result?.data?.remaining)
          } else {
              setTime("0")
              setPercent(100)
              clearInterval(interval)
              cloneSuccessful(true)
          }
      })
    const interval = setInterval(() => {
      api.get_status(linodeId).then((result) => {
          if (result?.data?.percent !== 100) {
              setPercent(result?.data?.percent)
              setTime(result?.data?.remaining)
          } else {
              setTime("0")
              setPercent(100)
              clearInterval(interval)
              cloneSuccessful(true)
          }
      })
  }, 15000);

    return () => clearInterval(interval);
  }, [])

  const handleBoot = () => {
      api.boot(linodeId).then((result) => {
          setStatus("running")
          setOpenSuccess(true)
      }).catch((err) => {
          setOpenFailed(true);
      })
  }
  const handleReboot = () => {
      api.reboot(linodeId).then((result) => {
          setStatus("running")
          setOpenSuccess(true)
      }).catch((err) => {
          setOpenFailed(true);
      })
  }
  const handleShutdown = () => {
      api.shutdown(linodeId).then((result) => {
          setStatus("offline")
          setOpenSuccess(true)
      }).catch((err) => {
          setOpenFailed(true);
      })
  }


  return (
      <Box>
          <Snackbar
            anchorOrigin={{ vertical:"top", horizontal:"center" }}
            open={openSuccess}
            autoHideDuration={10000}
            onClose={closeSuccess}
          >
            <Alert
                onClose={closeSuccess}
                severity="success"
                elevation={6} variant="filled"
            >
              {"Action successfully completed, please allow up to 15 secs."}
          </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical:"top", horizontal:"center" }}
            open={openFailed}
            autoHideDuration={6000}
            onClose={closeFailed}
          >
            <Alert
                onClose={closeFailed}
                severity="error"
                elevation={6} variant="filled">
              {"Action failed, please try again later"}
            </Alert>
          </Snackbar>
        {percent !== null && percent < 100
        ?
          <Stack justifyContent="center" spacing={1}>
            <Typography variant="caption">Your Endpoint is starting, Please wait...</Typography>
            <div>
              <CMCircularProgress value={percent} color="success"/>
            </div>
            <div>
                <Typography variant="caption">{getHourOrMinute(time)}</Typography>
            </div>
            <div>
                <Typography variant="caption">{"While you're waiting, join our discord for updates!"}</Typography>
            </div>
            <Link href="https://discord.gg/j9PJfwJHFR" target="_blank">Discord</Link>
          </Stack>

        : <Box>
            { status === "running"
              ?
                <img style={{width:"56px"}} src={powerOn} alt=""/>
              :
                <img style={{width:"56px"}} src={powerOff} alt=""/>
            }
            <Stack direction={{xs: "column", md:"row"}} spacing={1} my={2}>
                <Button color="success"
                  variant="contained"
                  fullWidth
                  onClick={handleBoot}
                  disabled={status === "running" || percent !== 100}>
                    Boot
                </Button>
                <Button color="error"
                  variant="contained"
                  fullWidth
                  onClick={handleShutdown}
                  disabled={status === "offline"}>
                    Shutdown
                </Button>
                <Button
                  color="warning"
                  variant="contained"
                  fullWidth
                  onClick={handleReboot}
                  disabled={status === "offline"}>
                    Restart
                </Button>
            </Stack>

        </Box>
        }
      </Box>
  )
}

export default RenderStatus
