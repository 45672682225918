import React, { useState, useEffect } from "react";
import { api } from "../api";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";

import ServerLocation from "../components/ServerLocation";
import SubscriptionBox from "../components/SubscriptionBox";
import EndpointType from "../components/EndpointType";

const types = [
  {
    value: "SRT",
    label: "SRT/SRTLA",
  },
  {
    value: "RTMP",
    label: "RTMP",
  },
];

const Order = ({ isAuthed, openFail }) => {
  const [regions, setRegions] = useState();
  const [endpointType, setType] = useState(0);
  const [duration, setDuration] = useState(1);
  const [location, setLocation] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [region, setRegion] = useState("ap-northeast");

  const handleTypeChange = (index) => {
    setType(index);
  };

  const handleDurationChange = (index) => {
    setDuration(index);
  };

  const handleLocationChange = (index, region) => {
    setLocation(index);
    setRegion(region);
  };

  const handleSubmit = (e) => {
    if (!isAuthed) {
      openFail("Please log in first to purchase!");
      return;
    }
    const data = {
      lookup_key: duration + "m",
      type: endpointType === 0 ? "srt" : "rtmp",
      location: region,
    };
    api.create_payment(data).then((result) => {
      const url = result.data?.url;
      if (url) {
        window.location.href = url;
      }
    });
  };

  useEffect(() => {
    api.get_regions().then((result) => {
      setRegions(result?.data);
    });
  }, []);

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h6" mb={3}>
          Endpoint Type
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <EndpointType
              title="SRT/SRTLA"
              index={0}
              value={endpointType}
              onClicked={handleTypeChange}
            >
              {
                "This service is for Belabox, Larix, and anything that supports SRT/SRTLA"
              }
            </EndpointType>
          </Grid>
          <Grid item xs={12} md={6}>
            <EndpointType
              title="RTMP"
              index={1}
              value={endpointType}
              onClicked={handleTypeChange}
            >
              {
                "Thise service is for LiveU, GoPro, DJI Mimo, and anything that supports RTMP streaming"
              }
            </EndpointType>
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Typography variant="h6" mb={3}>
          Server location
        </Typography>
        <Grid container spacing={1}>
          {
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "20vh",
                  alignItems: "center",
                }}
              >
                <Typography>
                  All new orders are serviced automatically at{" "}
                  <a href="https://IRLhosting.com">IRLhosting.com</a>
                </Typography>
              </Box>
            </Grid>
          }
        </Grid>
      </Box>
      <Box mb={4}>
        <Typography variant="h6" mb={3}>
          Duration
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8} md={4}>
            <SubscriptionBox
              checked={duration}
              onClicked={handleDurationChange}
              index={1}
              price={"$14.99"}
            >
              1 Month
            </SubscriptionBox>
          </Grid>
          <Grid item xs={12} sm={8} md={4}>
            <SubscriptionBox
              checked={duration}
              onClicked={handleDurationChange}
              index={3}
              originalPrice={"44.97"}
              price={"42.99"}
            >
              3 Month
            </SubscriptionBox>
          </Grid>
          <Grid item xs={12} sm={8} md={4}>
            <SubscriptionBox
              checked={duration}
              onClicked={handleDurationChange}
              index={6}
              originalPrice={"84.94"}
              price={"72.99"}
            >
              6 Month
            </SubscriptionBox>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          sx={{ maxWidth: "50%" }}
          onClick={handleSubmit}
          variant="contained"
          color="info"
          disabled
          fullWidth
        >
          Pay & Deploy
        </Button>
      </Box>
    </Box>
  );
};

export default Order;
