import React, { useState, useEffect } from 'react'

import { api } from "../../api";

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import server from "../../assets/server.png"
import powerOff from "../../assets/power-off.png"
import powerOn from "../../assets/power.png"

import Endpoint from "./Endpoint";

const Linodes = () => {
    const [endpoints, setEndpoints] = useState()

    const refresh = () => {
        setEndpoints()
        api.get_endpoints().then((result) => {
            setEndpoints(result?.data)
        })
    }
    useEffect(() => {
        refresh()
    }, [])
  return (
    <>
        {endpoints ?
            endpoints.length === 0 ?
                <Grid item xs={12}>
                    <Stack sx={{ display: 'flex', justifyContent: "center", height:"10vh", alignItems:"center" }}>
                        <Typography mb={2}>No Endpoints found! If you have recently purchased one, click to refresh:</Typography>
                        <Button variant="outlined" color="info" onClick={refresh}>Refresh</Button>
                    </Stack>
                </Grid>
            : endpoints.map((ep, index) => (
                <Endpoint ep={ep} key={index} refresh={refresh}/>
            ))
            :
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: "center", height:"10vh", alignItems:"center" }}>
                    <CircularProgress />
                </Box>
            </Grid>
        }
    </>
  )
}

export default Linodes
