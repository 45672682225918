import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Snackbar from "@mui/material/Snackbar";
import Alert from '@mui/material/Alert';

import Order from '../Order/Order';
import User from '../User/User';


import { useSearchParams } from 'react-router-dom';

import { api } from '../api';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt:4, borderRadius:5}}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



const Homepage = () => {
  const [value, setValue] = useState(0);
  const [isAuthed, setAuthed] = useState(Boolean(api.token()));
  const [searchParams, setSearchParams] = useSearchParams();
  const [openSuccess, setOpenSuccess] = useState(searchParams.get("success"));
  const [openFailed, setOpenFailed] = useState(false);
  const [failContent, setFailContent] = useState("");

  const refreshAuth = () => {
      setAuthed(Boolean(api.token()));
  }

  const closeSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const closeFailed = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailed(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openFailAlert = (content) => {
      setFailContent(content);
      setOpenFailed(true);
  }

  useEffect(() => {
      if (openSuccess) {
          setValue(1)
      }
  }, [openSuccess])



  return (
    <Box sx={{paddingTop:10}}>
        <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"center" }}
          open={openSuccess}
          autoHideDuration={10000}
          onClose={closeSuccess}
        >
          <Alert
              onClose={closeSuccess}
              severity="success"
              elevation={6} variant="filled"
          >
            {"Endpoint Successfully purchased. Activation process in progress"}
        </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"center" }}
          open={openFailed}
          autoHideDuration={6000}
          onClose={closeFailed}
        >
          <Alert
              onClose={closeFailed}
              severity="error"
              elevation={6} variant="filled">
            {failContent}
          </Alert>
        </Snackbar>

      <Grid container justifyContent="center">
        <Grid item xs={12} md={9}>
            <Order isAuthed={isAuthed} openFail={openFailAlert}/>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Homepage;
