import axios from "axios";
const URL = "https://api.u3.gg/ep";
// const URL = "http://localhost:8000/ep";

class Api {
  token() {
    return localStorage.getItem("site-token");
  }
  login(data) {
    return axios.post(`${URL}/auth/login/`, data)
  }
  register(data) {
    return axios.post(`${URL}/auth/register/`, data)
  }
  create_payment(data) {
    return axios.post(`${URL}/api/pay/`, data, {
        headers: {
          Authorization: `Token ${this.token()}`,
        },
      }
    );
  }
  get_regions() {
      return axios.options(`${URL}/api/regions/`)
  }
  get_transactions() {
      return axios.get(`${URL}/api/transactions/`, {
          headers: {
            Authorization: `Token ${this.token()}`,
          },
        })
  }
  get_endpoints() {
      return axios.get(`${URL}/api/endpoints/`, {
          headers: {
            Authorization: `Token ${this.token()}`,
          },
        })
  }
  get_plans() {
      return axios.get(`${URL}/api/core/products/`)
  }
  get_status(id) {
      return axios.get(`${URL}/api/status/${id}`, {
          headers: {
            Authorization: `Token ${this.token()}`,
          },
        })
  }
  boot(id) {
      return axios.post(`${URL}/api/endpoint/boot/${id}`, {}, {
          headers: {
            Authorization: `Token ${this.token()}`,
          },
      })
  }
  reboot(id) {
      return axios.post(`${URL}/api/endpoint/restart/${id}`, {}, {
          headers: {
            Authorization: `Token ${this.token()}`,
          },
      })
  }
  shutdown(id) {
      return axios.post(`${URL}/api/endpoint/shutdown/${id}`, {}, {
          headers: {
            Authorization: `Token ${this.token()}`,
          },
      })
  }
  cancel_subscription(id) {
      return axios.delete(`${URL}/api/subscription/cancel/${id}`, {
          headers: {
            Authorization: `Token ${this.token()}`,
          },
      })
  }
  reset_password(email) {
      return axios.post(`${URL}/auth/auth/users/reset_password/`, {
          "email": email,
      })
  }
  reset_password_confirm(data) {
      return axios.post(`${URL}/auth/auth/users/reset_password_confirm/`, data)
  }
}

export const api = new Api();
