import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';

import Plan from './components/Plan';

import { api } from '../api';

const Hosting = () => {
  const [plans, setPlans] = useState([]);
  const [isAuthed, setAuthed] = useState(Boolean(api.token()));

  useEffect(() => {
    api.get_plans().then((result) => {
        setPlans(result.data)
    })
  }, [])

  return (
    <Grid container justifyContent="center" p={2} mt={"8vh"}>
      <Grid item xs={12} md={9}>
          <Box mb={2}>
            <Typography variant="h6">Cloud Hosting</Typography>
            <Grid container justifyContent="center" spacing={2} alignItems="stretch">
                {plans?.map((plan) => (
                    <Grid item xs={10} md={5}
                        sx={{
                            marginTop:5.5,
                        }}
                    >
                        <Plan plan={plan}/>
                    </Grid>
                ))}
            </Grid>
          </Box>
      </Grid>
    </Grid>
  )
}

export default Hosting;
