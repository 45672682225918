import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MuiLink from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { Link } from 'react-router-dom';

import ColorToggle from './colorToggle';

import DiscordSVG from "../assets/Discord.svg";

const pages = [
  {
    name: "Endpoint",
    link: "/endpoint"
  },
  {
    name: "Hosting",
    link: "/hosting",
  }
]

const Header = (props) => {
    // <Box sx={{flexGrow:1}}>
    //   <Typography variant="h5" component="h1">Endpoint Creator</Typography>
    // </Box>
  return (
      <Box sx={{
          position:"fixed",
          top:0,
          left:0,
          right:0,
          height: "40px",
          paddingTop: 2,
          paddingBottom: 1,
      }}>
        <Container maxWidth="md" sx={{
            width:"100%",
            display:"flex",
            justifyContent:"right",
            alignItems:"center",
        }}>
          <Stack direction="row" spacing={1} sx={{flexGrow:1}}>
            {pages.map((page) => (
              <Box>
                <Button component={Link} to={page.link}>{page.name}</Button>
                <Menu></Menu>
              </Box>
            ))}
          </Stack>
          <Stack direction="row" pr={1}>
              <Box>
                <Button component={Link} to={"account/"}>{"Account"}</Button>
                <Menu></Menu>
              </Box>
          </Stack>
          <ColorToggle darkMode={props.darkMode} DarkModeToggle={props.DarkModeToggle}/>
        </Container>
      </Box>
  )
}

export default Header;
