import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import Login from './components/Login';
import Transactions from './components/Transactions';
import Linodes from './components/Linodes';

import { api } from '../api';

const StyledTransaction = styled(Box)(({ theme }) => ({

}));

const User = ({ }) => {
    const [isAuthed, setIsAuthed] = useState(Boolean(api.token()));

    const refreshAuth = () => {
        setIsAuthed(Boolean(api.token()));
    }

    const logout = () => {
        localStorage.removeItem("site-token");
        refreshAuth();
    }
  return (
    <Box sx={{
            marginTop: 15,
        }}
    >
        {isAuthed ?
            <Grid container justifyContent="center">
                <Grid item xs={12} md={9}>
                    <Box sx={{width:"100%", display:"flex"}}>
                      <Typography sx={{marginRight:"auto"}} variant="h6">Endpoints</Typography>
                      <Button color="error" variant="contained" onClick={logout}>Logout</Button>
                    </Box>
                    <Grid container item xs={12} spacing={2} mb={2} alignItems="stretch">
                        <Linodes />
                    </Grid>
                    <Typography variant="h6">Transactions</Typography>
                    <Grid container item xs={12}spacing={2}>
                        <Transactions />
                    </Grid>
                </Grid>
            </Grid>
        :
            <Login refreshAuth={refreshAuth} />}
    </Box>
  )
}

export default User;
