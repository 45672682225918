import React, { useState, useEffect } from 'react'

import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const EndpointType = ({children, title, value, index, onClicked}) => {
    const [isChecked, setChecked] = useState(value === index);

    const handleChange = (e) => {
        onClicked(index);
    }

    useEffect(() => {
        setChecked(value === index);
    }, [value])

    return (
      <Paper elevation={isChecked? 5 : 1}
        sx={{
            px:1,
            py:2,
            position:"relative",
            cursor:"pointer",
        }}
        onClick={handleChange}>
        <Stack direction="row">
          <Box sx={{display:"flex", alignItems:"center"}}>
            <Checkbox checked={isChecked} onChange={handleChange}/>
          </Box>
          <Box mx={1}>
            <Typography fontWeight="bold" mb={1}>{title}</Typography>
            <Typography variant="body2" fontWeight="light">
              {children}
            </Typography>
          </Box>
        </Stack>
        {isChecked &&
            <Box sx={{
                position: "absolute",
                top:0,
                right:0,
                width:0,
                height:0,
                borderTop:"0px solid transparent",
                borderBottom: "40px solid transparent",
                borderRight: "40px solid #007bfc"
            }} />
        }
      </Paper>
    )
}

export default EndpointType
