import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const Plan = ({ plan }) => {

  return (
    <Paper
        sx={{
          p: 2,
          position:"relative",
          borderRadius:2,
          minHeight:"50vh",
          height:"50vh",
          display:"flex",
          flexDirection:"column",
        }}
      >
        <Paper elavation={6} sx={{
          position:"absolute",
          top: -30,
          left: 50,
          right: 50,
          zIndex: 10,
          height: "130px",
          borderRadius:2,
          bgcolor: 'warning.main'
        }}>
          <Stack textAlign="center" pt={3}>
            <Typography fontWeight="bold">{plan?.name}</Typography>
            <Typography fontWeight="bold" mt={1}>${plan.price}/Month</Typography>
          </Stack>
        </Paper>
        <Box mt={"110px"} textAlign="center">
          <List>
            {plan?.features?.features_list.map((feature) => (
                <ListItem sx={{justifyContent:"center"}}>{feature}</ListItem>
            ))}
          </List>
        </Box>
        <Box sx={{
                pt:2,
                mt:"auto",
                mb:1,
                display:"flex",
                justifyContent:"center"
            }}>
            <Button sx={{width:"70%"}} color="success" variant="contained" fullWidth>Buy Now</Button>
        </Box>
    </Paper>
  )
}

export default Plan;
