import React, { useState } from 'react'

import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Snackbar from "@mui/material/Snackbar";
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import ForgotPassword from "./ForgotPassword";

import { api } from '../../api';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt:4, borderRadius:5}}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Login = ({ refreshAuth }) => {
  const [data, setData] = useState({});
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState();

  const closeSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setData({...data, [name]:value})
  }

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleLogin = (data) => {
      api.login(data).then((result) => {
          localStorage.setItem("site-token", result?.data?.token)
          localStorage.setItem("username", result?.data?.username)
          refreshAuth();
      }).catch((err) => {
          setOpenSuccess(true)
      })
  }

  const handleSubmit = (e) => {
    setLoading(true)
    if (e.target.name === "login") {
        handleLogin({username: data.lusername, password: data.lpassword})
    } else if (e.target.name === "signup") {
        api.register({
            username: data.susername,
            email: data.semail,
            password: data.spassword,
        }).then((result) => {
            handleLogin({username: data.susername, password: data.spassword})
        })
    }
    setLoading(false)
  }

  return (
    <Box>
        <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"center" }}
          open={openSuccess}
          autoHideDuration={10000}
          onClose={closeSuccess}
        >
          <Alert
              onClose={closeSuccess}
              severity="error"
              elevation={6} variant="filled"
          >
            {"Username/password incorrect"}
          </Alert>
        </Snackbar>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={9}>
              <Box sx={{display:"flex", justifyContent:"center"}}>
                <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs">
                  <Tab label="Login" {...a11yProps(0)} />
                  <Tab label="Signup"  {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                  <Grid container justifyContent="center" spacing={3}>
                      <Grid item xs={12} md={8}>
                          <TextField fullWidth value={data?.lusername} onChange={handleChange} name="lusername" label="Username"/>
                      </Grid>
                      <Grid item xs={12} md={8}>
                          <TextField fullWidth value={data?.lpassword} onChange={handleChange} name="lpassword" label="Password" type="password"/>
                      </Grid>
                      <Grid item container xs={12} md={8} justifyContent="center">
                          <Box sx={{
                              width: {xs:"90%", md:"60%",}
                          }}>
                              <LoadingButton
                                  fullWidth
                                  variant="contained"
                                  name="login"
                                  loading={loading}
                                  onClick={handleSubmit}
                              >
                                  Login
                              </LoadingButton>
                          </Box>
                      </Grid>
                  </Grid>
                  <Box>
                    <ForgotPassword />
                  </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item xs={12} md={8}>
                      <TextField fullWidth value={data?.susername} onChange={handleChange} name="susername" label="Username"/>
                  </Grid>
                  <Grid item xs={12} md={8}>
                      <TextField fullWidth value={data?.semail} onChange={handleChange} name="semail"label="Email"/>
                  </Grid>
                  <Grid item xs={12} md={8}>
                      <TextField fullWidth value={data?.spassword} onChange={handleChange} name="spassword" label="Password" type="password"/>
                  </Grid>
                  <Grid item container xs={12} md={8} justifyContent="center">
                      <Box sx={{
                          width: {xs:"90%", md:"60%",}
                      }}>
                          <LoadingButton
                              fullWidth
                              variant="contained"
                              name="signup"
                              loading={loading}
                              onClick={handleSubmit}
                          >
                                Signup
                          </LoadingButton>
                      </Box>
                  </Grid>
                </Grid>
              </TabPanel>
          </Grid>
        </Grid>
    </Box>
  )
}

export default Login
