import React, { useState, useEffect } from 'react'

import Flag from 'react-flagkit';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { styled } from '@mui/system';

const blue = {
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
};

const CustomPaper = styled(Paper)`
  transition: all 150ms ease;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: ${blue[600]};
  }

  &.active {
    background-color: ${blue[700]};
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ServerLocation = ({city, country, flag, value, index, onClicked, region}) => {
  const [isChecked, setChecked] = useState(value === index);

  const handleChange = (e) => {
    onClicked(index, region);
  }

  useEffect(() => {
    setChecked(value === index);
  }, [value])

  return (
    <CustomPaper sx={{
        p: 2,
        position:"relative",
        display:"flex",
        maxWidth:"300px",
      }}
      elevation={isChecked? 5 : 1}
      onClick={handleChange}
    >
        <Stack spacing={2} sx={{flexGrow:1}} direction="row">
            <Flag country={flag} />
            <Typography>{city}</Typography>
        </Stack>
        <Typography variant="caption" sx={{lineHeight:"1.6rem"}}>{country}</Typography>
        {isChecked &&
            <Box sx={{
                position: "absolute",
                top:0,
                right:0,
                width:0,
                height:0,
                borderTop:"0px solid transparent",
                borderBottom: "30px solid transparent",
                borderRight: "30px solid #007bfc"
            }} />
        }
    </CustomPaper>

  )
}

export default ServerLocation
