import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { api } from '../../api';

import AutoDeleteIcon from '@mui/icons-material/AutoDelete';

const DeleteLinode = ({linode_id, onConfirm}) => {
    const [isOpen, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        setLoading(true)
        api.cancel_subscription(linode_id).then((result) => {
            setTimeout(() => {
                onConfirm();
                setLoading(false);
            }, 3000)
        })
        setOpen(false);
    }



    return (
        <>
        <Box sx={{
            position:"absolute",
            right:5,
            top:5,
        }}>
            <Stack>
                <IconButton onClick={handleClickOpen}>
                    <AutoDeleteIcon color="error" />
                </IconButton>
            </Stack>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={(e) => setLoading(false)}
              >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>

        <Dialog
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Cancel your subscription?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              The endpoint will be deleted at the end of your billing cycle, are you sure?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDelete} color="error">Confirm</Button>
            <Button onClick={handleClose} autoFocus color="success">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        </>
    );
}

export default DeleteLinode
