import React, { useState } from 'react'

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { api } from '../../api';

const ForgotPassword = () => {
    const [forgotten, setForgotten] = useState(false);
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(0);
    const [msg, setMsg] = useState(0);

    const resetPassword = () => {
        api.reset_password(email).then((result) => {
            setSuccess(1);
            setMsg("If the email is correct, We have sent the password reset link to your email");
        }).catch((err) => {
            setSuccess(-1);
            setMsg("An error has occured, please contact us in discord");
        })
    }


  return (
      <Box sx={{textAlign:"center"}} mt={2}>
        <Typography
          component={Button}
          variant="caption"
          onClick={(e) => setForgotten(!forgotten)}
          >Forgotten your password?</Typography>
        {forgotten &&
            <Stack mt={2} justifyContent="center" alignItems="center">
                <Box sx={{display:"flex"}} width="66%" justifyContent="center" mb={2}>
                    <TextField onChange={(e) => setEmail(e.target.value)} fullWidth label="Enter your Email" />
                </Box>
                <Box sx={{
                    width: {xs:"90%", md:"38%"}
                }}>
                    <Button onClick={resetPassword} fullWidth variant="contained" color="info">Reset</Button>
                </Box>
                {success !== 0 &&
                    <Typography mt={2} color={success === 1 ? "green" : "error"}>{msg}</Typography>
                }
            </Stack>
        }
      </Box>
  )
}

export default ForgotPassword
